import produce from "immer";
import { Types as AuthTypes } from "./auth";

export const UserTypes = {
  UPDATE_USER_REQUEST: "@user/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "@user/UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "@user/UPDATE_USER_ERROR",
  UPDATE_REWARDS_PROGRAM: "@user/UPDATE_REWARDS_PROGRAM",
  REMOVE_USER: "@user/REMOVE_USER",
  GET_ACCOUNT_REQUEST: "@user/GET_ACCOUNT_REQUEST",
  GET_ACCOUNT_SUCCESS: "@user/GET_ACCOUNT_SUCCESS",
  GET_ACCOUNT_FAILURE: "@user/GET_ACCOUNT_FAILURE",
  REMOVE_LOADING: "@user/REMOVE_LOADING"
};

const INITIAL_STATE = {
  profile: JSON.parse(localStorage.getItem('@ConexaoGAM:user')) || null,
  loading: false
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case AuthTypes.SIGN_IN_SUCCESS: {
        draft.profile = action.payload.user;
        break;
      }
      case AuthTypes.SIGN_OUT: {
        draft.profile = null;
        break;
      }
      case UserTypes.UPDATE_USER_REQUEST: {
        draft.loading = true;
        break;
      }
      case UserTypes.UPDATE_USER_SUCCESS: {
        draft.loading = false;
        if (action.payload?.userProfile !== null) {
          draft.profile = action.payload.userProfile;
          localStorage.setItem("@ConexaoGAM:user", JSON.stringify(action.payload.userProfile));
        }
        break;
      }
      case UserTypes.UPDATE_USER_ERROR: {
        draft.loading = false;
        break;
      }
      case UserTypes.GET_ACCOUNT_REQUEST: {
        draft.loading = true;
        break;
      }
      case UserTypes.GET_ACCOUNT_SUCCESS: {
        draft.profile = action.payload.data;
        draft.loading = false;
        break;
      }
      case UserTypes.GET_ACCOUNT_FAILURE: {
        draft.loading = false;
        break;
      }
      case UserTypes.REMOVE_USER: {
        draft.loading = true;
        break;
      }
      case UserTypes.REMOVE_LOADING: {        
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  updateUserRequest: (cpf, nome, email, dataNascimento, celular, genero, aceitaOfertas) => ({
    type: UserTypes.UPDATE_USER_REQUEST,
    payload: { nome, email, celular, genero, dataNascimento, cpf, aceitaOfertas }
  }),

  updateUserSuccess: (userProfile = null) => ({
    type: UserTypes.UPDATE_USER_SUCCESS,
    payload: { userProfile }
  }),

  updateUserError: () => ({
    type: UserTypes.UPDATE_USER_ERROR
  }),

  removeUser: (cpf) => ({
    type: UserTypes.REMOVE_USER,
    payload: { cpf }
  }),

  updateRewardsProgram: () => ({
    type: UserTypes.UPDATE_REWARDS_PROGRAM
  }),

  getAccountRequest: () => ({
    type: UserTypes.GET_ACCOUNT_REQUEST
  }),

  getAccountFailure: () => ({
    type: UserTypes.GET_ACCOUNT_FAILURE
  }),

  getAccountSuccess: (data = null) => ({
    type: UserTypes.GET_ACCOUNT_SUCCESS,
    payload: { data }
  }),

  removeLoading: () => ({
    type: UserTypes.REMOVE_LOADING
  })
};
