import { put, call, select } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../services/history";
import { toast } from "react-toastify";
import { Creators as UserActions } from "../ducks/user";
import { Creators as AuthActions } from "../ducks/auth";
import { Creators as ErrorActions } from "../ducks/error";

export function* updateUser({ payload }) {
  try {
    const { uuid } = yield select(state => state.user.profile);
    const { nome, email, celular, genero, dataNascimento, cpf, aceitaOfertas } = payload;

    const { data } = yield call(api.put, `customers/${uuid}`, {
      nome,
      email,
      celular,
      genero,
      dataNascimento,
      cpf,
      aceitaOfertas
    });

    const { message, cliente } = data;

    toast.success(message);
    yield put(UserActions.updateUserSuccess(cliente));
  } catch (err) {
    yield put(UserActions.updateUserError());
    yield put(ErrorActions.setError("user-update", err.response.data.error));
  }
}

export function* getAccount() {
  try {
    const token = yield localStorage.getItem("@ConexaoGAM:token");

    if (token === null) {
      yield put(UserActions.getAccountFailure());
      return;
    }

    const response = yield call(api.get, "customers/account");
    yield localStorage.setItem(
      "@ConexaoGAM:user",
      JSON.stringify(response.data)
    );
    yield put(UserActions.getAccountSuccess(response.data));
  } catch (err) {
    yield put(UserActions.getAccountFailure());
    yield put(AuthActions.signOut());
    history.push("/entrar");
  }
}

export function* removeUser({ payload }){
  try {
    const { cpf } = payload;
    const { uuid } = yield select(state => state.user.profile);

    const response = yield call(api.put, `customers/${uuid}/deactive`, { cpf })

    yield put(AuthActions.signOut());
    yield put(UserActions.removeLoading());
  } catch (err) {
    yield put(ErrorActions.setError("user-update", err.response.data.error));
  }
}