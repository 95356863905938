import styled from "styled-components";

export const Container = styled.a`
    position: fixed;
    bottom: 20px;
    right: 25px;
    background-color: #31d64c;
    padding: 15px;
    border-radius: 30px;
    font-size: 0px;
`;