import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Container } from "./styles";

function FloatingWhatsapp({ whatsappPhoneNumber }) {
    return (
        <Container href={`https://api.whatsapp.com/send?phone=${whatsappPhoneNumber}`} target="_blank" rel="noopener noreferrer">
            <IoLogoWhatsapp height={50} width={30} color="white" fontSize={30} />
        </Container>
    );
}

export default FloatingWhatsapp;