import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import history from "../../services/history";
import store from "../../store";
import { Creators as AuthActions } from "../ducks/auth";
import { Creators as CartActions } from "../ducks/cart";

export function* signIn({ payload }) {
  try {
    const { email, senha, redirectCheckout } = payload;

    const loja = store.getState().store.data;

    let uuid = '';
    if (loja !== null)
      uuid = loja.empresa?.uuid;

    const response = yield call(api.post, `sessions/${uuid}`, {
      email,
      senha
    });

    const { accessToken, refreshToken, cliente } = response.data;

    yield localStorage.setItem("@ConexaoGAM:token", accessToken)
    yield localStorage.setItem("@ConexaoGAM:refreshToken", refreshToken)
    yield localStorage.setItem("@ConexaoGAM:user", JSON.stringify(cliente))

    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
    yield put(AuthActions.signInSuccess(accessToken, refreshToken, cliente));

    yield put(CartActions.getOrderAndCartFromStorage());

    if (redirectCheckout) {
      history.push("/checkout/revisao");
    } else {
      history.push("/");
    }

    window.location.reload()
  } catch (err) {
    yield put(AuthActions.signFailure(err.response.data.error));
  }
}

export function* signOut() {
  yield localStorage.removeItem("@ConexaoGAM:token");
  yield localStorage.removeItem("@ConexaoGAM:refreshToken");
  yield localStorage.removeItem("@ConexaoGAM:user");
  yield put(AuthActions.signOutSuccess());
  history.push("/");
}
