import styled from "styled-components";

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;

  .headerTitle{
    padding: 30px 30px 0 30px;
  }

  > h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 2.4rem;
    font-weight: 500;
  }

  span.withdrawal-time {
    color: #57B85B;
    font-weight: 400;

    strong {
      font-weight: 600;
      color: #57B85B;
    }
  }

  .subtitleArea {
    display: block;
    justify-content: space-between;
    width: 100%;
  }

  .subtitleAreaFlex {
    display: flex;
    flex-direction: row;
  }

  .timeLineOverFlow {
    width: auto;
    overflow-x: auto;
    white-space: nowrap
  }

  .subtitle{
    flex: 1;

    span {
      font-size: 14px;
      color: ${(props) => props.theme.colors.neutral600};
    }
  }
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 76px;

  @media (max-width: 920px) {
    flex-direction: column;
  }
`;

export const OrderItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-between;

  @media (max-width: 920px) {
    width: 100%;
  }

  >div {
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 25px;
  }
`;

export const ItemDetail = styled.div`
  padding-bottom: 20px;
  h1 {
    font-size: 20px;
    color: ${(props) => props.theme.colors.neutral900};
    font-weight: 700;
  }
`;

export const Items = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  font-size: 1.8rem;
  align-items: center;
  font-weight: 500;
  color: ${(props) => props.theme.colors.headingLight};

  > span {
    width: 15%;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;

    strong {
      font-size: 14px;
      font-weight: 700;
      color: ${(props) => props.theme.colors.neutral900};
      padding: 2px 0 0 10px;
    }

    > div {
      font-size: 1.6rem;
      display: flex;
      padding: 10px 0 0 10px;
      width: 100%;

      > span {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.neutral900};
        margin-right: 15px;
      }

      >.rightSide{
        font-weight: 700;
        text-align: right;
        width: 100%;
        margin-right: 0;
      }
    }
  }

  img {
    padding: 10px;
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 620px) {
    flex-direction: column;

    > span {
      width: 100%;
      padding: 10px 0;
    }

    div {
      strong {
        width: 100%;
        padding: 10px 0;
        text-align: center;
      }

      span {
        padding: 3px 0;
      }

      div {
        display: flex;
        align-items: center;
      }

      width: 100%;
      padding: 5px 0;
      justify-content: center;
    }
  }
`;

export const Status = styled.div`
  align-items: center;
  display: flex;
  margin: 10px 0;

  @media (max-width: 365px) {
    flex-direction: column;

    strong {
      padding: 5px 0;
    }

    span {
      padding: 5px 0;
    }
  }

  strong {
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.headingLight};
    font-weight: 500;
    margin-right: 5px;
  }

  span {
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.white};
    font-weight: 500;
    font-size: 14px;
    padding: 0 5px;
    border-radius: 4px;
  }

  >.PF {
    background-color: ${(props) => props.theme.colors.primary};
  }

  >.PE, >.PA {
    background-color: ${(props) => props.theme.colors.alertsuccess};
  }

  >.AP, >.PS, >.PL, >.AR, >.AE, >.AC {
          background-color: ${(props) => props.theme.colors.warning};
  }

  >.PC, >.PT, >.FP {
          background-color: ${(props) => props.theme.colors.error};
  }
`;

export const PurchaseSummary = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${(props) => props.theme.colors.headingLight};

  hr {
    margin: 5px 0;
  }

  div {
    margin: 15px 0;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  strong {
    font-size: 1.4rem;
    font-weight: 500;
    padding: 10px 0;
  }

  h1 {
    font-size: 2.2rem;
    padding: 20px 0 0 0;
    font-weight: 500;
  }

  h3 {
    font-size: 1.4rem;
  }

  .order-header {
    p {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 0;
      text-align: center;
      align-items: center;
    }

    .bold {
      font-weight: 500;
    }
  }

  .order-address, .expected-time {
    h3 {
      font-size: 20px;
      font-weight: 700;
      font-color: ${(props) => props.theme.colors.neutral900};
      margin-bottom: 10px;
    }

    .capitalize {
      text-transform: capitalize;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      width: 100%;
      padding: 5px 0;
      align-items: center;
    }
  }

  .store-time{
    justify-content: space-between;

    strong{
      padding: 0;
    }
  }

  .order-third-retreat{
    margin: 15px 0 0 0;

    .bold {
      font-weight: 500;
    }

    .no-bold {
      font-weight: 400;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      font-color: ${(props) => props.theme.colors.neutral900};
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 0;
      text-align: left;
      align-items: left;
    }

    .small {
      font-size: 1.4rem;
    }
  }

  .order-payment {
    .bold {
      font-weight: 500;
    }

    .no-bold {
      font-weight: 400;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      font-color: ${(props) => props.theme.colors.neutral900};
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 0;
      text-align: center;
      align-items: center;
    }

    .small {
      font-size: 1.4rem;
    }
  }

  .order-subtotals {
    h3 {
      font-size: 20px;
      font-weight: 700;
      font-color: ${(props) => props.theme.colors.neutral900};
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 7px 0;
    }

    .bold {
      font-weight: 500;
    }
  }
`;

