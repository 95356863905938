import React from "react"
import BuySteps from "../BuySteps"
import { FooterCompact } from "../Footer"
import { Container } from "./styles"
import { useSelector } from "react-redux"
import FloatingWhatsapp from "../FloatingWhatsapp"

const BuyStepsTemplate = ({ children, history, step }) => {
    const { telefone_whatsapp, exibir_whatsapp } = useSelector((state) =>
        state.store.data == null ? "" : state.store.data.empresa
    );

    return (
        <Container>
            <BuySteps step={step} history={history} />
            {children}
            <FooterCompact />
            { exibir_whatsapp && telefone_whatsapp && <FloatingWhatsapp whatsappPhoneNumber={`55${telefone_whatsapp.replace(/\D/g, '')}`} /> }
        </Container>
    )
}

export default BuyStepsTemplate;