import React from 'react'
import {CheckboxContainer, HiddenCheckbox, StyledCheckbox, Icon, Label} from './styles';

const Checkbox = ({ className, name, checked, setFieldValue, labelText, ...props }) => (
    <CheckboxContainer onClick={() => setFieldValue(name, !checked)} className={className}>    
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
    <Label>{labelText}</Label>
  </CheckboxContainer>
)

export default Checkbox;