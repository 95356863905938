import { darken } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MaskedInputCustom from "../../components/MaskedInputCustom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledLinkLogo = styled(Link)`
  margin-top: 40px;
  align-self: center;

  img {
    width: 134px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.white};
  border: 1px solid #E8E8E9;
  border-radius: 4px;
  margin: 40px 0px;
  padding: 64px;
`;

export const FormTitle = styled.header`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2.3rem;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.headingLight};
`;

export const StyledMaskedInputCustom = styled(MaskedInputCustom)`
  width: 60%;
  align-self: flex-start;
`;

export const SignIn = styled.span`
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  text-decoration: none;
  margin: 32px auto 0;

  a {
    outline: none;
    cursor: pointer;
    font-size: 16px;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary};
    margin-left: 5px;

    @media (max-width: 768px) {
      margin-top: 8px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LabelCheckBox = styled.label`

`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
export const AcceptTerms = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.bodyLight};
  padding: 30px 0 10px 0;

  + div {
    padding: 10px 0 20px 0;
  }

  p {
    max-width: 600px;
  }

  label {
    line-height: 28px;
    padding-top: 10px;
  }

  button {
    background: transparent;
    border: none;
    color: ${props => props.theme.colors.primary};
    font-weight: 700;
    text-decoration: none;
  }
`;
