
// Constantes para status do pedido
export const PedidoStatus = {
  aguardandoPagamento: "AP",
  aguardandoEntrega: "AE",
  aguardandoRetirada: "AR",
  aguardandoCancelamento: "AC",
  pedidoRealizado: "PF",
  pedidoCancelado: "PC",
  pagamentoAprovado: "PA",
  pedidoEntregue: "PE",
  pedidoSeparacao: "PS",
  pedidoLido: "PL",
  pedidoTeste: "PT",
  falhaPagamento: "FP",
};

export const PedidoStatusExtenso = {
  aguardandoPagamento: "Aguardando Pagamento",
  aguardandoEntrega: "Aguardando Entrega",
  aguardandoRetirada: "Aguardando Retirada",
  aguardandoCancelamento: "Aguardando Cancelamento",
  pedidoRealizado: "Pedido Realizado",
  pedidoCancelado: "Pedido Cancelado",
  pagamentoAprovado: "Pagamento Aprovado",
  pedidoEntregue: "Pedido Entregue",
  pedidoSeparacao: "Pedido em Separação",
  pedidoLido: "Pedido Separado",
  pedidoTeste: "Pedido Teste",
  falhaPagamento: "Falha no Pagamento",
};

// Funções utilitárias
export const convertPedidoStatusExtenso = (pedidoStatus) => {
  switch (pedidoStatus) {
    case PedidoStatus.aguardandoPagamento:
      return PedidoStatusExtenso.aguardandoPagamento;
    case PedidoStatus.aguardandoEntrega:
      return PedidoStatusExtenso.aguardandoEntrega;
    case PedidoStatus.aguardandoRetirada:
      return PedidoStatusExtenso.aguardandoRetirada;
    case PedidoStatus.aguardandoCancelamento:
      return PedidoStatusExtenso.aguardandoCancelamento;
    case PedidoStatus.pedidoRealizado:
      return PedidoStatusExtenso.pedidoRealizado;
    case PedidoStatus.pedidoCancelado:
      return PedidoStatusExtenso.pedidoCancelado;
    case PedidoStatus.pagamentoAprovado:
      return PedidoStatusExtenso.pagamentoAprovado;
    case PedidoStatus.pedidoEntregue:
      return PedidoStatusExtenso.pedidoEntregue;
    case PedidoStatus.pedidoSeparacao:
      return PedidoStatusExtenso.pedidoSeparacao;
    case PedidoStatus.pedidoLido:
      return PedidoStatusExtenso.pedidoLido;
    case PedidoStatus.pedidoTeste:
      return PedidoStatusExtenso.pedidoTeste;
    case PedidoStatus.falhaPagamento:
      return PedidoStatusExtenso.falhaPagamento;
    default:
      return "Status desconhecido";
  }
};
