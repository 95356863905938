import produce from "immer";

export const Types = {
  OPEN_MODAL: "@signup/OPEN_MODAL",
  CLOSE_MODAL: "@signup/CLOSE_MODAL",
  SIGN_UP_REQUEST: "@signup/SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "@signup/SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "@signup/SIGN_UP_FAILURE"
};

const INITIAL_STATE = {
  isOpen: false,
  isAuthenticated: false,
  data: null,
  loading: false
};

export default function signup(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.OPEN_MODAL: {
        draft.isOpen = true;
        break;
      }
      case Types.CLOSE_MODAL: {
        draft.isOpen = false;
        break;
      }
      case Types.SIGN_UP_REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SIGN_UP_SUCCESS: {
        draft.loading = false;
        draft.data = null;
        break;
      }
      case Types.SIGN_UP_FAILURE: {
        draft.loading = false;
        draft.data = null;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  openSignUpModal: () => ({
    type: Types.OPEN_MODAL
  }),

  closeSignUpModal: () => ({
    type: Types.CLOSE_MODAL
  }),

  signUpRequest: (
    cpf,
    nome,
    email,
    senha,
    dataNascimento,
    celular,
    genero,
    programaFidelidade,
    lgpd_aceite,
    aceitaOfertas
  ) => ({
    type: Types.SIGN_UP_REQUEST,
    payload: {
      cpf,
      nome,
      email,
      senha,
      dataNascimento,
      celular,
      genero,
      programaFidelidade,
      lgpd_aceite,
      aceitaOfertas
    }
  }),

  signUpSuccess: () => ({
    type: Types.SIGN_UP_SUCCESS
  }),

  signUpFailure: () => ({
    type: Types.SIGN_UP_FAILURE
  })
};
