import React from "react";
import { ToastContainer } from "react-toastify";
import { FooterCompact } from "../Footer";
import Header from "../Header";
import ScrollToTop from "../ScrollToTop";
import { useSelector } from "react-redux";
import FloatingWhatsapp from "../FloatingWhatsapp";

const MainLayoutCompact = ({ children, history }) => {
  const { telefone_whatsapp, exibir_whatsapp } = useSelector((state) =>
    state.store.data == null ? "" : state.store.data.empresa
  );

  return (
    <>
      <ScrollToTop />
      <ToastContainer autoClose={2500} />
      <Header history={history} />
      {children}
      <FooterCompact />
      {exibir_whatsapp && telefone_whatsapp && <FloatingWhatsapp whatsappPhoneNumber={`55${telefone_whatsapp.replace(/\D/g, '')}`} />}
    </>
  );
}

export default MainLayoutCompact;
